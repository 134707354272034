import {
  type ConnectionLogger, type HandshakeLogger, type Handshaker, PolicyRequirement, type ReverseWebsocketClient,
  ReverseWebsocketConnection,
  ReverseWebsocketTransportServer,
} from '@p3ki/p3ki.js'
import type {OperationExecution} from '@/model/operation/operation-execution'
import type {Ref} from 'vue'
import {ref} from 'vue'
import type {Operation} from '@/model/operation/operation'


export class ReverseWebsocketOperationConnection extends ReverseWebsocketConnection {
  operation?: Operation<any>
  execution: Ref<OperationExecution<any> | undefined>

    constructor(
      handshaker: Handshaker,
      client: ReverseWebsocketClient,
      path: string,
      operation?: Operation<any>,
      policyRequirement?: PolicyRequirement,
      logger?: ConnectionLogger<string>,
      handshakeLogger?: HandshakeLogger,
  ) {
    super(handshaker, client, path, policyRequirement, logger, handshakeLogger)
    this.operation = operation
    this.execution = ref()
  }
}

interface OperationServer {
  operations: Operation<any>[]
  payloadForOperation(operation: Operation<any>, connectArgs?: Record<string, any>): string
  get url(): URL | undefined

}

export class ReverseWebsocketOperationServer extends ReverseWebsocketTransportServer {
    public operations: Operation<any>[]

    public constructor(
      wsTunnelServerUrl: URL,
      slug: string,
      handshaker: Handshaker,
      operations: Operation<any>[],
      label?: string,
      policyRequirement?: PolicyRequirement,
      connectionLoggerFactory?: (id?: string) => ConnectionLogger<string> | undefined,
      handshakeLoggerFactory?: (id?: string) => HandshakeLogger | undefined,
    ) {
      super(
          wsTunnelServerUrl,
          slug,
          handshaker,
          label,
          policyRequirement,
          false,
          connectionLoggerFactory,
          handshakeLoggerFactory,
        )
        this.operations = operations
    }

  protected async _newClient(client: ReverseWebsocketClient, path: string): Promise<void> {
    let operationName = path
    while (operationName.startsWith('/')) operationName = operationName.substring(1)
    const conn = new ReverseWebsocketOperationConnection(
      this._handshaker,
      client,
      path,
      this.operationByName(operationName),
      this.policyRequirement,
      this._connectionLoggerFactory(client.id),
      this._handshakeLoggerFactory(client.id),
    )
    await this._newConnection(conn)
     if (conn.operation) {
        await conn.establish(conn.operation.policyRequirement)
        conn.execution.value = conn.operation.execute(conn)
        await conn.execution.value
        await conn.close(true, 'operation executed')
      } else if (operationName) {
          await conn.close(false, 'unknown operation: '+ operationName)
      } else {
          await conn.establish()
      }
  }

  payloadForOperation(operation: Operation<any>, connectArgs?: Record<string, any>): string {
    return JSON.stringify({
      type: 'connect',
      url: this.url + '/' + operation.name,
      ...(connectArgs ? connectArgs : {}),
    })
  }

  public operationByName(name: string): Operation<unknown> | undefined {
    return this.operations.find(o => o.name === name)
  }
}