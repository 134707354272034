<script setup lang="ts">
  import {
    mdiAccountQuestion, mdiCheck, mdiClose
  } from '@mdi/js'
  import { computed, onUnmounted, reactive, type Ref, ref, watch } from 'vue'

  import {
    Identity,
    type TrinityClient,
    SimpleHandshaker,
    randomString,
    TransportServerState,
    MemoryLogger,
    type ConnectionLogger,
    type HandshakeLogger,
    type LIN, resolveIdentities, resolveIdentity, type Tags, Connection, Policy,
  } from '@p3ki/p3ki.js'
  import {
    Await,
    IdentityView
  } from '@p3ki/p3ki-vue'
  import config from '@/config'
  import {
    ReverseWebsocketOperationConnection,
    ReverseWebsocketOperationServer
  } from '@/model/transport/operationServer'
  import OperationDetails from '@/components/OperationDetails.vue'
  import { useI18n } from 'vue-i18n'
  import type { Operation } from '@/model/operation/operation'
  import {OperationExecution} from '@/model/operation/operation-execution'


  const { t } = useI18n()

  export interface Props {
    trinityClient: TrinityClient
    identity: Identity | LIN | PromiseLike<Identity | LIN>,
    identities?: Identity[] | PromiseLike<Identity[]> | undefined,
    identityRouteResolve?: CallableFunction,
    disabled?: boolean,
    tags?: Tags,
    operations: Operation<any>[]
    policySuggestions?: Policy[]
  }

  const props = defineProps<Props>()

  const identitiesPromise: Ref<Promise<Identity[]>> = computed(() => resolveIdentities(props.trinityClient, props.identities))
  const identityPromise: Ref<Promise<Identity>> = computed(() => resolveIdentity(props.trinityClient, props.identity, identitiesPromise.value))

  function wsTunnelUrl() {
    let url = config.wsTunnelLocation
    if (!url.startsWith('ws')) {
      url = (window.location.protocol === 'https:' ? 'wss' : 'ws') + '://' + window.location.host + url
    }
    return new URL(url)
  }

  const operationServers: Ref<ReverseWebsocketOperationServer[]> = ref([])

  async function openTransports(identity: Identity) {
    operationServers.value.push(new ReverseWebsocketOperationServer(
      wsTunnelUrl(),
      randomString(10),
      new SimpleHandshaker(props.trinityClient, identity),
      [...props.operations],
      'RevWebSoc Server',
      undefined,
      () => reactive(new MemoryLogger()) as ConnectionLogger<string>,
      () => reactive(new MemoryLogger()) as HandshakeLogger,
    ))
    for (const server of operationServers.value) {
      try {
        await server.open()
      } catch (e) {
        console.error(e)
      }
    }
  }

  async function closeTransports() {
    for (const server of operationServers.value) await server.close()
    operationServers.value.splice(0, operationServers.value.length)
  }
  onUnmounted(closeTransports)
  watch(() => identityPromise.value, async (i: Promise<Identity>) => {
    await closeTransports()
    if (i) await openTransports(await i)
  }, {immediate: true, deep: false})

  function castConn(c: Connection<any>): ReverseWebsocketOperationConnection {
    return c as ReverseWebsocketOperationConnection
  }

  const emit = defineEmits(['deleted'])

  function deleted(identity: Identity) {
    emit('deleted', identity)
  }
</script>

<template>
  <v-container style="max-width: 800px; padding-bottom: 128px;">
    <await
      :resolve="identityPromise"
      :fail-icon="mdiAccountQuestion"
      v-slot="{result: identity}: {result: Identity}"
      :fail-message="t('identity not found')"
    >
      <identity-view
        :trinity-client="trinityClient"
        :actions="['delete', 'qr']"
        :identity="identity"
        :identities="identitiesPromise"
        :identity-route-resolve="identityRouteResolve"
        :disabled="disabled"
        :tags="tags"
        :transport-servers="operationServers"
        :policy-suggestions="policySuggestions"
        @deleted="deleted"
      >
        <template #transportServerAdditon="{transportServer}" >
          <v-card-text class="pb-0" v-if="transportServer.operations && transportServer.operations.length > 0 && transportServer.state === TransportServerState.OPEN">
            <b>{{ t('operation', 2) }}</b>
            <div class="operations mt-2">
              <operation-details
                v-for="operation in transportServer.operations"
                :key="operation.name"
                class="mb-4 mr-3 d-inline-block"
                :operation="operation"
                :payload="transportServer.payloadForOperation(operation)"
              />
            </div>
          </v-card-text>
        </template>

        <template #transportServerConnectionHeadAdditon>
              <th>{{ t('operation') }}</th>
              <th>{{ t('execution') }}</th>
        </template>

        <template #transportServerConnectionRowAddition="{connection}">
          <td>{{ castConn(connection).operation?.name || '-' }}</td>
          <td>
            <div v-if="castConn(connection).execution" class="d-flex align-center">
              <v-icon v-if="(castConn(connection).execution as unknown as OperationExecution<any>).wasSuccessful" :icon="mdiCheck" color="success" />
              <v-icon v-else-if="(castConn(connection).execution as unknown as OperationExecution<any>).hasFailed" :icon="mdiClose" color="error" />
              <v-progress-circular v-else :indeterminate="true" />
              <div class="ml-2">
                <div>{{ (castConn(connection).execution as unknown as OperationExecution<any>).label }}</div>
                <div class="text-small">{{ (castConn(connection).execution as unknown as OperationExecution<any>).caption }}</div>
              </div>
            </div>
            <div v-else>-</div>
          </td>
        </template>
      </identity-view>
    </await>
  </v-container>
</template>

