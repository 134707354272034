import {createWebHistory, type RouterOptions} from 'vue-router'
import ScenarioView from '@/views/ScenarioView.vue'
import IdentitiesView from '@/views/IdentitiesView.vue'
import IdentityView from '@/views/IdentityView.vue'
import {type Identity} from '@p3ki/p3ki.js'
import {Scenario} from '@/model/scenario'

export function identityRouteResolve(identity?: Identity) {
  if (identity) {
    return {name: 'IdentityView', params: {lin: identity.lin}}
  } else {
    return {name: 'IdentitiesView'}
  }
}

function createRouterOptions(scenario: Scenario): RouterOptions {
  console.log(scenario.policy_suggestions)
  return {
    history: createWebHistory(scenario.baseUrl || '/'),
    routes: [{
      path: '/',
      name: 'ScenarioView',
      component: ScenarioView,
      props: to=> ({
        isCurrent: to.name === 'ScenarioView',
        scenario: scenario,
      }),
      children: [{
        path: ':nodeName',
        name: 'IdentitiesView',
        component: IdentitiesView,
        props: to => ({
          isCurrent: to.name === 'IdentitiesView',
          identityRouteResolve: identityRouteResolve,
          policySuggestions: scenario.policy_suggestions,
          node: scenario.nodes.find(n => n.name.toLowerCase() === (to.params.nodeName as string).toLowerCase())
        }),
        children: [{
          path: ':lin',
          name: 'IdentityView',
          component: IdentityView,
          props: to=> ({
            identity: to.params.lin as string,
            operations: scenario.nodes.find(n => n.name.toLowerCase() === (to.params.nodeName as string).toLowerCase())!.operations
          }),
        }],
      }],
    }],
  }
}

export default createRouterOptions
