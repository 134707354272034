<script setup lang="ts">
  import {RouterView, useRoute} from 'vue-router'
  import {watch} from 'vue'

  const route = useRoute()

  const defaultTitle = document.title
  watch(() => route.params, ({nodeName, lin}) => {
    let title = defaultTitle
    if (nodeName) title = nodeName + ' | ' + title
    if (lin) title = lin + '@' + title
    document.title = title
  })

</script>

<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<style>
.text-monospace {
  font-family: monospace;
}
.text-small {
  font-size: .7em;
}
.text-dense {
  line-height: .89em;
}
.full-overlay {
  position: absolute;
  left:0;
  top: 0;
  right:0;
  bottom:0;
}
.ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
