import {Execution} from '@/model/execution'
import {Connection} from '@p3ki/p3ki.js'
import axios from 'axios'

export abstract class OperationExecution<T> extends Execution<T> {
    protected _connection: Connection<string>

    protected constructor(connection: Connection<string>) {
        super()
        this._connection = connection
        this._connection.onClose(event => {
            this._fail('connection closed', 'connection closed', event.reason)
        })
        this.then(
            () => this._connection.close(true, 'operation executed'),
            () => this._connection.close(false, 'operation failed'),
        )
    }

    public get connection(): Connection<string> {
        return this._connection
    }
}

export abstract class ProxyOperationExecution<T> extends OperationExecution<T> {
    protected constructor(connection: Connection<string>) {
        super(connection)
        this._connection.onMessage(payload => this._sendToProxyTarget(payload))
    }

    protected _receivedFromProxyTarget(payload: string): Promise<void> {
        return this._connection.send(payload)
    }

    protected abstract _sendToProxyTarget(payload: string): Promise<void>
}

export class PostProxyOperationExecution extends ProxyOperationExecution<unknown> {
    url: string

    constructor(connection: Connection<string>, url: string) {
        connection.onClose(event => {
            if (event.clean) {
                this._accomplish('connection closed', 'connection closed', event.reason)
            } else {
                this._fail('connection closed', 'connection closed', event.reason)
            }
        })
        super(connection)
        this.url = url
    }

    protected async _sendToProxyTarget(payload: string): Promise<void> {
        try {
            const response = await axios.post(this.url, payload)
            this._receivedFromProxyTarget(JSON.stringify(response.data))
        } catch (e) {
            this._fail(e, '' + e)
        }
    }
}

export class PostOperationExecution extends OperationExecution<unknown> {
    constructor(connection: Connection<string>, url: string, payload: string) {
        super(connection)
        setTimeout(() => {
            axios.post(url, payload).then(
                response => {
                    this._connection.send(JSON.stringify(response.data))
                        .then(
                            () => this._accomplish(response.data, 'operation executed'),
                            err => this._fail(false, 'sending post response failed: ' + err)
                        )
                },
                err => this._fail(err, 'post request failed', '' + err),
            )
        }, 3000)
    }
}

export class SuccessfulOperationExecution<T> extends OperationExecution<T> {
    constructor(connection: Connection<string>, result: T, label?: string, caption?: string) {
        super(connection)
        this._accomplish(result, label, caption)
        this._connection.send(JSON.stringify(this.toPlain())).finally(() => {
            connection.close(true, 'operation executed')
        })

    }
}