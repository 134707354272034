<script setup lang="ts">
  import { IdentitiesView } from '@p3ki/p3ki-vue'
  import {P3KINode} from '@/model/scenario'
  import {Identity, Policy, RestTagStore, TrinityHttpClientV2} from '@p3ki/p3ki.js'
  import {computed} from 'vue'

  export interface Props {
    node: P3KINode,
    isCurrent: boolean,
    identityRouteResolve?: CallableFunction,
    policySuggestions?: Policy[]
  }
  const props = defineProps<Props>()


  const trinityClient = computed(() => new TrinityHttpClientV2(
    props.node.trinityUrl.toString(),
    new RestTagStore('/_api/tags'),
  ))

  async function created(identity: Identity) {
    identity.tags['trinityUrl'] = props.node.trinityUrl.toString();
    identity.tags['p3kiNode'] = props.node.name
    await trinityClient.value.updateIdentity(identity.lin, identity.tags)
  }
</script>

<template>
  <v-theme-provider :theme="node.theme" with-background>
    <identities-view
      :trinity-client="trinityClient"
      :name="node.name"
      :logo="node.logo"
      :is-current="isCurrent"
      :always-show-name="node.alwaysShowName"
      :identity-route-resolve="identityRouteResolve"
      :policy-suggestions="policySuggestions"
      @created="created"
    />
  </v-theme-provider>
</template>


