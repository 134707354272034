<script setup lang="ts">
  import {mdiConsoleLine, mdiPalette} from '@mdi/js'
  import {RouterView} from 'vue-router'
  import {Scenario} from '@/model/scenario'
  import {useI18n} from 'vue-i18n'
  const { t } = useI18n()
  export interface Props {
    scenario: Scenario,
    isCurrent: boolean,
  }
  defineProps<Props>()
</script>

<template>
    <template v-if="isCurrent">
      <div class="checkered">
        <v-container style="min-height: 100vh;">
          <div class="text-center mb-6 mt-12">
            <h2>{{ t('selectNode', 2) }}</h2>
          </div>
          <div
            class="d-flex flex-row justify-center align-content-stretch flex-wrap"
          >
            <v-card
              v-for="node in scenario.nodes"
              :key="node.name"
              elevation="5"
              style="background-color: transparent; width: 220px"
              class="ma-4 position-relative"
              :to="{name: 'IdentitiesView', params: {nodeName: node.name}}"
            >
              <v-theme-provider
                :theme="node.theme"
                with-background
                style="height: 100%;"
              >
                <div
                  class="pa-4 pb-2"
                  style="background-color: rgb(var(--v-theme-appbar));"
                >
                  <img
                    class="d-inline-block"
                    :src="node.logo?.toString()"
                    style="height: 20px;"
                    :alt="node.name"
                  />
                </div>
                <v-card class="overflow-hidden ma-4">
                  <v-card-title class="ellipsis">
                    {{ node.name }}
                  </v-card-title>
                  <v-card-text>
                    <div>
                      <v-icon :icon="mdiPalette" :start="true" />
                      {{node.theme}}
                    </div>
                    <div>
                      <v-icon :icon="mdiConsoleLine" :start="true" />
                      {{ node.operations.length }} {{ t('operation', node.operations.length) }}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      variant="flat"
                      color="primary"
                      :text="t('select')"
                    />
                  </v-card-actions>
                </v-card>
              </v-theme-provider>
            </v-card>
          </div>
          <div class="text-center mt-12">
            <h2>{{ t('Util', 2) }}</h2>
          </div>
          <div class="d-flex flex-row justify-center align-content-stretch flex-wrap text-center">

            <v-card
              elevation="5"
              style="width: 200px"
              class="ma-4 position-relative"
              href="/_admin"
              title="Administration"
            />
            <v-card
              elevation="5"
              style="width: 200px"
              class="ma-4 position-relative"
              href="/_delegraph"
              title="Delegation Graph"
            />
            <v-card
              elevation="5"
              style="width: 200px"
              class="ma-4 position-relative"
              href="/_jaeger"
              title="Tracing"
            />
          </div>
          </v-container>
        </div>
      </template>
      <router-view v-else />
<!--      <router-view v-else v-slot="{ Component }">-->
<!--        <component :is="Component" :node="" />-->
<!--      </router-view>-->
    <div style="clear: both;"></div>
</template>

<style scoped>
.checkered {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
  background-repeat: repeat, repeat;
  background-position: 0px 0, 5px 5px;
  transform-origin: 0 0 0;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  background-size: 10px 10px, 10px 10px;
  box-shadow: none;
  text-shadow: none;
  transition: none;
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
</style>
