import { createVuetify } from 'vuetify'
import {PACKAGE_VERSION as P3KI_VUE_VERSION, BUILD_DATE as  P3KI_VUE_BUILD_DATE} from '@p3ki/p3ki-vue'
import {LIB_VERSION as P3KI_JS_VERSION, BUILD_DATE as  P3KI_JS_BUILD_DATE} from '@p3ki/p3ki.js'
import {App} from 'vue'

export default function logVersions(app: App) {
  const versions = [
    {name: 'p3ki-showroom-frontend '   , version: APP_VERSION,        buildDate: new Date(BUILD_DATE), styles: ['font-size:1.5em;', 'font-size:1.5em;', '']},
    {name: 'p3ki-js     ', version: P3KI_JS_VERSION,  buildDate: P3KI_JS_BUILD_DATE},
    {name: 'p3ki-vue    ', version: P3KI_VUE_VERSION, buildDate: P3KI_VUE_BUILD_DATE},
    {name: 'vue         ', version: app.version},
    {name: 'vuetify     ', version: createVuetify.version},
  ]

  for (const {name, version, buildDate, styles} of versions) {
    let line = `%c${name}%c${version}%c`
    if (buildDate) line += ` (built ${buildDate.toISOString().split('T')[0]})`
    console.log(line, ...(styles || ['', '', '']))
  }
}
