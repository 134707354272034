import axios from 'axios'
import {Scenario} from '@/model/scenario'
import config from '@/config'
import {Operation} from '@/model/operation/operation'
import {PolicyRequirement} from '@p3ki/p3ki.js'
import {PostOperation} from '@/model/operation/post-operation'
import {SimpleOperation} from '@/model/operation/simple-operation'

const scenarioPromise: Promise<Scenario> = axios.get<Scenario>(config.scenarioUrl)
  .then(response => {
    response.data.nodes.forEach((n: Record<string, any>) => {
      if (n.operations) {
        const ops: Operation<any>[] = []
        n.operations.forEach((opConf: Record<string, any>) => {
          const policy_req = new PolicyRequirement(
            opConf.required,
            opConf.optional,
            opConf.language,
          )
          if (opConf.successPost) {
            ops.push(new PostOperation(opConf.name, opConf.successPost, policy_req))
          } else {
            ops.push(new SimpleOperation(opConf.name, policy_req, opConf.successLabel, opConf.successCaption))
          }
        })
        // ops.push(new FormMockOperation('loginform'))
        n.operations = ops
      }
    })
    return response.data
  },
  error => {
    const element = document.getElementById('app')!
    element.className = 'text-center'
    element.innerHTML = '<div>failed to load configuration from </div><div class="text-monospace">' + config.scenarioUrl + '</div><div>' + error.message + '</div>'
    throw error
  }
)

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'
import createRouterOptions from '@/router'
import {createRouter} from 'vue-router'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import {createI18n} from 'vue-i18n'
import {i18n} from '@p3ki/p3ki-vue'
import { mdi, aliases } from 'vuetify/iconsets/mdi-svg'
const app = createApp(App)
import logVersions from './version-log'
logVersions(app)

import '@p3ki/p3ki-vue/dist/style.css'

scenarioPromise.then(scenario => {

    app.use(createRouter(createRouterOptions(scenario)))

    app.use(createI18n({
          legacy: false,
          locale: localStorage.getItem('locale') || scenario.locale || 'en',
          fallbackLocale: scenario.fallbackLocale || 'en',
          fallbackWarn: false,
          missingWarn: false,
          messages: i18n,
        }))

    scenario.themes._scenario = {dark: false}
    app.use(createVuetify({
        components,
        directives,
        theme: {
            defaultTheme: '_scenario',
            themes: scenario.themes,
        },
        icons: {
            defaultSet: 'mdi',
            aliases,
            sets: { mdi },
        },
    }))

    app.mount('#app')
}, () => {})


