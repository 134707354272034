import type {Connection, PolicyRequirement} from '@p3ki/p3ki.js'
import type {OperationExecution} from '@/model/operation/operation-execution'

export abstract class Operation<T> {
    public readonly name: string
    public readonly policyRequirement?: PolicyRequirement

    constructor(name: string, policyRequirement?: PolicyRequirement) {
        this.name = name
        this.policyRequirement = policyRequirement
    }

    public abstract execute(connection: Connection<string>): OperationExecution<T>
}