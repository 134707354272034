import {Operation} from '@/model/operation/operation'
import {Execution, SuccessfulExecution} from '@/model/execution'

import {Connection, PolicyRequirement} from '@p3ki/p3ki.js'
import type {OperationExecution} from '@/model/operation/operation-execution'
import {SuccessfulOperationExecution} from '@/model/operation/operation-execution'

export class SimpleOperation extends Operation<undefined> {
    public label?: string
    public caption?: string

    constructor(name: string, policyRequirement?: PolicyRequirement, label?: string, caption?: string) {
        super(name, policyRequirement)
        this.label = label
        this.caption = caption
    }

    public execute(connection: Connection<string>): SuccessfulOperationExecution<undefined> {
        return new SuccessfulOperationExecution<undefined>(connection, undefined, this.label, this.caption)
    }
}