import {Operation} from '@/model/operation/operation'
import type {Connection, PolicyRequirement} from '@p3ki/p3ki.js'
import {PostOperationExecution} from '@/model/operation/operation-execution'



export class PostOperation extends Operation<unknown> {
    public readonly url: string

    constructor(name: string, url: string, policyRequirement?: PolicyRequirement) {
        super(name, policyRequirement)
        this.url = url
    }

    public execute(connection: Connection<string>): PostOperationExecution {
        return new PostOperationExecution(connection, this.url, JSON.stringify(connection.requireEstablished()))
    }
}