import type {Ref} from 'vue'
import {computed, ref} from 'vue'
import type {ComputedRef} from 'vue'
import {Deferred} from '@p3ki/p3ki.js'


export enum ExecutionState {
    INITIALIZED = 'INITIALIZED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
}

export class ExecutionInfo<T> {
    public readonly state: Ref<ExecutionState>
    public readonly label: Ref<string | undefined>
    public readonly caption: Ref<string | undefined>
    public readonly progress: Ref<number | undefined>
    public readonly input: Ref<ExecutionInput[] | undefined>
    public readonly result: Ref<T | undefined>
    public readonly error: Ref<any>

    public readonly wasSuccessful: ComputedRef<boolean>
    public readonly hasFailed: ComputedRef<boolean>
    public readonly isFinished: ComputedRef<boolean>
    public readonly inputValues: ComputedRef<Record<string, any> | undefined>

    constructor(state: ExecutionState = ExecutionState.INITIALIZED, label?: string, caption?: string) {
        this.state = ref(state)
        this.label = ref(label)
        this.caption = ref(caption)
        this.progress = ref()
        this.input = ref()
        this.result = ref()
        this.error = ref()

        this.wasSuccessful = computed(() =>  this.state.value === ExecutionState.COMPLETE)
        this.hasFailed = computed(() =>  this.state.value === ExecutionState.FAILED)
        this.isFinished = computed(() => this.wasSuccessful.value || this.hasFailed.value)
        this.inputValues = computed(() => {
            if (!this.input.value) return undefined
            const inputValues: Record<string, any> = {}
            for (const input of this.input.value) {
                inputValues[input.key] = input.value
            }
            return inputValues
        })
    }

    public static parse<S>(src: Record<string, any>): ExecutionInfo<S> {
        const info = new ExecutionInfo<S>()
        info.state.value = src.state
        info.label.value = src.label || undefined
        info.caption.value = src.caption || undefined
        info.progress.value = src.progress
        info.input.value = src.input && src.input.length > 0 ? src.input : undefined
        info.result.value = src.result
        info.error.value = src.error || undefined
        return info
    }

    toPlain(): Record<string, any> {
        return {
            state: this.state.value,
            label: this.label.value,
            caption: this.caption.value,
            progress: this.progress.value,
            input: this.input.value,
            result: this.result.value,
            error: this.error.value,
        }
    }
}

export class ExecutionInput {
    key: string
    type: 'text' | 'password' | 'select' = 'text'
    label: string
    value?: unknown
    placeholder?: string
    icon?: string
    items?: string[]

    constructor(
        key: string,
        type?: 'text' | 'password' | 'select',
        label?: string,
        value?: unknown,
        placeholder?: string,
        icon?: string,
        items?: string[],
    ) {
        this.key = key
        this.type = type || 'text'
        this.label = label || key
        this.value = value
        this.placeholder = placeholder
        this.icon = icon
        this.items = items
    }
}

export abstract class Execution<T> extends ExecutionInfo<T> {
    private _deferred: Deferred<T>

    protected constructor() {
        super(ExecutionState.INITIALIZED)
        this._deferred = new Deferred<T>()
    }

    public then(
        onSuccess: (info: T) => any,
        onFail?: (info: any) => any,
    ) {
       return this._deferred.then(onSuccess, onFail)
    }

    public finally(onFinish: () => any) {
       return this._deferred.finally(onFinish)
    }

    public catch(onFail: (info: ExecutionInfo<T>) => any) {
        this._deferred.catch(onFail)
    }

    protected _accomplish(result: T, label?: string, caption?: string): void {
        if (this.state.value === ExecutionState.INITIALIZED || this.state.value === ExecutionState.IN_PROGRESS) {
            this.state.value = ExecutionState.COMPLETE
            this.result.value = result
            this.label.value = label
            this.caption.value = caption
            this.progress.value = 100
            this.input.value = undefined
            this._deferred.resolve(result)
        }
    }

    protected _fail(error?: any, label?: string, caption?: string) {
        if (this.state.value === ExecutionState.INITIALIZED || this.state.value === ExecutionState.IN_PROGRESS) {
            this.state.value = ExecutionState.FAILED
            this.error.value = error
            this.label.value = label
            this.caption.value = caption
            this.input.value = undefined
            this._deferred.reject(error)
        }
    }

    protected _apply(info: ExecutionInfo<T>) {
        if (info.state.value) {
            if (info.state.value === ExecutionState.COMPLETE) {
                this._accomplish(<T>info.result.value, info.label.value, info.caption.value)
            } else if (info.state.value === ExecutionState.FAILED) {
                this._fail(info.error.value, info.label.value, info.caption.value)
            } else if (info.state.value === ExecutionState.IN_PROGRESS) {
                if (this.state.value === ExecutionState.INITIALIZED || this.state.value === ExecutionState.IN_PROGRESS) {
                    this.caption.value = info.caption.value
                    this.label.value = info.label.value
                    this.input.value = info.input.value
                    this.progress.value = info.progress.value
                }
            } else if (info.state.value === ExecutionState.INITIALIZED) {
                if (this.state.value === ExecutionState.INITIALIZED) {
                    this.caption.value = info.caption.value
                    this.label.value = info.label.value
                    this.input.value = info.input.value
                    this.progress.value = info.progress.value
                }
            }
        }
    }
}


export class PromiseExecution<T> extends Execution<T> {
    constructor(promise: Promise<T>, label?: string, successLabel?: string) {
        super()
        this.label.value = label
        this.state.value = ExecutionState.IN_PROGRESS
        promise.then(
            r => this._accomplish(r, successLabel),
            err => this._fail(err),
        )
    }
}

export class SuccessfulExecution<T> extends Execution<T> {
    constructor(result: T, label?: string, caption?: string) {
        super()
        this.label.value = label
        this.state.value = ExecutionState.COMPLETE
        this._accomplish(result, label, caption)
    }
}
